import React from 'react'
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion';

function U1L3() {
  return (
    <div className='U1L3'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome!  </span>Today, we will be learning how to introduce ourselves!</center>
      </section>
      <section>
        <p className='text'>Let's begin with review!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={9}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={5}/> 
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' src='https://media.discordapp.net/attachments/407710496670875661/1137638046729240658/quackko_characters_14.png?width=468&height=468'></img>
        <TextInputQuestion index={7}/>
      </section>
      <section>
        <p className='text'>In the next section, there is an example of how you can use 係 (hai6) to introduce yourself.</p>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={8}/>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>Before introducing yourself with your name, you should start with a "Hello." In Cantonese, it is "你好 (nei5 hou2)."</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={10}/>
      </section>
      <section>
        <p className='text'>你 means "you" and 好 means "good." So, together, "hello" in Cantonese translates directly to "you good."</p>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt="Stroke Order of 好" src='https://media.discordapp.net/attachments/407710496670875661/1137674804393160754/quackko_characters_15.png?width=468&height=468'></img>
        <TextInputQuestion index={9}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={6}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={7}/>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U1L3

import React from 'react'
import './LessonBlock.css'
import Button from './Button'
import { useState } from 'react'
import { Link } from 'react-router-dom'

function LessonBlock() {

  const [selected, setSelected] = useState(null)

  const toggle = (i) => {
    if (selected === i){
      return setSelected(null)
    }
    setSelected(i)
  }

  return (
    <div className='wrapper'>
      <div className='accordion'>
        {data.map((item, i) => (
          <div className='item'>
            <div className='title' onClick={() => toggle(i)}>
              <div className='title-2'>
                <h3 className='lessonNumber'>{item.unitNumber}</h3>
                
              </div>
                <h2 className='lessonTitle'>{item.unitTitle}</h2>
                <p className='lessonDescription'>{item.unitDescription}</p>
            </div>
            <div className={selected === i ? 'content.show' : 'content'}>
              <div className='blocks'>
                <h3 className='lessonName'>Lesson 1 - {item.lesson_one_title}</h3>
                <Link className="link" to={item.lesson_one}><Button boxShadow="0px 4px #d8af34ef" fontSize="13px"color="#FFDC76" text="Start Lesson!"/></Link>
              </div>
              <div className='blocks'>
                <h3 className='lessonName'>Lesson 2 - {item.lesson_two_title}</h3>
                <Link className="link"  to={item.lesson_two}><Button boxShadow="0px 4px #d8af34ef" fontSize="13px"color="#FFDC76" text="Start Lesson!"/></Link>
              </div>
              <div className='blocks'>
                <h3 className='lessonName'>Lesson 3 - {item.lesson_three_title}</h3>
                <Link className="link"  to={item.lesson_three}><Button boxShadow="0px 4px #d8af34ef" fontSize="13px"color="#FFDC76" text="Start Lesson!"/></Link>
              </div>
              <div className='blocks'>
                <h3 className='lessonName'>Lesson 4 - {item.lesson_four_title}</h3>
                <Link className="link"  to={item.lesson_four}><Button boxShadow="0px 4px #d8af34ef" fontSize="13px"color="#FFDC76" text="Start Lesson!"/></Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const data = [
  {
    unitNumber: "Unit 0",
    unitTitle: "Getting Started with Quackko",
    unitDescription: "Learn how to effectively use Quackko to learn Cantonese!",
    lesson_one:"/lessons/U0L1",
    lesson_one_title: "Keyboards / Input",
    lesson_two:"/lessons/U0L2",
    lesson_two_title: "Jyutping Consonants", 
    lesson_three:"/lessons/U0L3",
    lesson_three_title: "Jyutping Vowels",
    lesson_four:"/lessons/U0L4",
    lesson_four_title: "Strokes"
  },
  {
    unitNumber: "Unit 1",
    unitTitle: "Form Basic Sentences",
    unitDescription: "Learn how to form basic sentences in Cantonese!", 
    lesson_one:"/lessons/U1L1",
    lesson_one_title: "Basic Grammar Structure 1",
    lesson_two_title: "Basic Verbs", 
    lesson_two:"/lessons/U1L2", 
    lesson_three_title: "Introduce Yourself", 
    lesson_three:"/lessons/U1L3", 
    lesson_four_title: "Numbers", 
    lesson_four:"/lessons/U1L4", 
  },
  {
    unitNumber: "Unit 2",
    unitTitle: "Descriptions",
    unitDescription: "Learn how to describe things and people in Cantonese!", 
    lesson_one:"/lessons/U2L1",
    lesson_one_title: "Quantity",
    lesson_two:"/lessons/U2L2", 
    lesson_two_title: "Basic Adjectives", 
    lesson_three_title: "Describing Food", 
    lesson_three:"/lessons/U2L3", 
    lesson_four_title: "Negative Food Descriptions", 
    lesson_four:"/lessons/U2L4", 
  },
  {
    unitNumber: "Unit 3",
    unitTitle: "Ordering Dim Sum",
    unitDescription: "Learn how to order dim sum and have conversations about dim sum!", 
    lesson_one:"/lessons/U3L1",
    lesson_one_title: "What is Dim Sum?",
  },
];

export default LessonBlock

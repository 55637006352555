import React from 'react';
import Header from './Header'; 
import './App.css';
import Landing from './Landing'
import { Route, Routes } from 'react-router-dom';
import LessonsPage from './LessonsPage';
import U0L1 from './U0L1';
import Login from './Login';
import SignUp from './SignUp'
import U1L1 from './U1L1';
import U0L2 from './U0L2'
import U0L3 from './U0L3'
import ContactPage from './ContactPage';
import U0L4 from './U0L4'
import U1L2 from './U1L2'
import U1L3 from './U1L3';
import U1L4 from './U1L4';
import U2L1 from './U2L1';
import U2L2 from './U2L2';
import U2L3 from './U2L3'; 
import U3L1 from './U3L1'
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="app_body">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/lessons" element={<LessonsPage />} />
          <Route path="/lessons/U0L1" element={<U0L1 />} />
          <Route path="auth/login" element={<Login />} />
          <Route path="auth/signup" element={<SignUp />} />
          <Route path="/lessons/U1L1" element={<U1L1/>} />
          <Route path="/lessons/U0L2" element={<U0L2 />}/>
          <Route path="/lessons/U0L3" element={<U0L3 />}/>
          <Route path="/contactQuackko" element={<ContactPage />}/>
          <Route path='/lessons/U0L4' element={<U0L4 />}/>
          <Route path='/lessons/U1L2' element={<U1L2 />}/>
          <Route path='/privacyPolicy' element={<PrivacyPolicy />}/>
          <Route path='/lessons/U1L3' element={<U1L3 />}/>
          <Route path='/lessons/U1L4' element={<U1L4 />}/>
          <Route path='/lessons/U2L1' element={<U2L1 />}/>
          <Route path='/lessons/U2L2' element={<U2L2 />}/>
          <Route path='/lessons/U2L3' element={<U2L3 />}/>
          <Route path='/lessons/U3L1' element={<U3L1 />}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;

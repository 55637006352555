import React from 'react'
import './U0L2.css'
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion'
import TextInputQuestion from './TextInputQuestion'
import Popup from './Popup'
import JyutpingTones from './JyutpingTones'

function U0L2() {
  return (
    <div className='U0L2'>
      <BottomBar />
      <section className="welcome">
        <center className='text'>Welcome back to <span className="welcomeQuackko">Quackko!</span></center>
      </section>
      <section>
        <p className='text'>When learning a language, most learn to read, write, and speak the language. Cantonese uses Chinese characters, making reading challenging for most beginners because one would have to match sounds with characters. </p>
      </section>
      <section>
        <p className='text'>To make things easier, Jyutping is used. Jyutping is the romanization of the characters, meaning that it is in the Roman script instead, which is easier for English-speaking learners. </p>
      </section>
      <section>
        <p className='text'>For example, Nei5 Hou2 is the Romanization of the characters 你好. Now that it is Romanized, adding indications of each word's tone will help us pronounce it even better. Cantonese, similar to Mandarin, has tones. There are 6 essential tones to remember. </p>
      </section>
      <section>
        <p className='text'>One way to remember the different Cantonese tones is to associate each tone with a specific word that uses that tone. By doing this, you can use the word you know as a reference point to help you identify the correct tone for other words. </p>
      </section>
      <section>
        <p className='text'>For example, if you associate the word "你 (Nei5)" with the 5th tone (low rising tone), you can use that as a reference to help you recognize other words that use the 5th tone. So, every time you encounter a new word, you can compare its tone to the tone of the word you know to help you figure out how to say a word with its proper tone.</p>
      </section>
      <section>
        <p className='text'>After each word, there will be a number. The number is the tone. For example, “Nei5” indicates that it is pronounced “nei” with the 5th tone. </p>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={3}/>
      </section>
      <section>
        <img alt="the 6 tones in Cantonese"className="mobileIMG" src='https://media.discordapp.net/attachments/407710496670875661/1127109059909259264/IMG_8600.png'></img>
        <p className='text'>Here is a chart of the 6 tones in Cantonese! The start of the arrow is the start of the word. The end of the arrow is the end of the word. Following this chart and the arrows will help you pronounce the words with proper tone.</p>
      </section>
      <section>
      <p className='text'>Every word below is romanized the same, but has different tones. Listen to the examples of each tone below:</p>
        <JyutpingTones />
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>Although romanization makes it easier for English speakers, not all of the words are pronounced the same way they would in English. </p>
      </section>
      <section>
        <img className="mobileIMG" alt='C in Jyutping makes a "Ch" sound in English' src="https://media.discordapp.net/attachments/1046659695345143820/1127111561975181382/High_2.png?width=1102&height=576"></img>
        <p className='text'>For example, in English, “c” would make a hard “cuh” sound. In Jyutping, it would make a soft “ch” sound. The sound is close to a “ts” sound. </p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={0}/>
      </section>
      <section className="welcome">
        <p className='text'>Let's go over some more examples of letters at the start of a word that can be confusing in Jyutping! </p>
      </section>
      <section>
        <img alt="J doesn't make a 'juh' sound in Jyutping. It is, instead, the sound of a y (yuh)."className="mobileIMG"src="https://media.discordapp.net/attachments/407710496670875661/1127112053233037373/High_3.png?width=1102&height=576"></img>
        <p className='text'>In English, the “j” sound makes a “juh” sound. But, in Jyutping, the “j” sound makes the “y” sound in English (yuh).</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={1}/>
      </section>
      <section>
        <p className='text'>For the "z" in Jyutping, it is between a "j" and a "z" sound in English. It is not a hard "z" sound. It is a softer sound and can sound like a "j" sound.</p>
      </section>
      <section>
        <p className='text'>In the next lesson, there will be a continuation of Jyutping. This lesson has covered the consonants! In the next lesson, we will learn more about the vowels!</p>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U0L2

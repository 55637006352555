import React from 'react'
import "./U1L1.css"
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'

function U1L1() {
  return (
    <div className='U1L1'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome!  </span>Today, we will be learning how to form basic sentences!</center>
      </section>
      <section>
        <p className='text'>Similar to English and many other languages, basic sentences in Cantonese can be formed using the Subject + Verb + Object (SVO) Grammar Structure.
</p>
      </section>
      <section>
        <p className='text'>One of the most used subjects (nouns) is “I.” The word for “I” in Cantonese is ngo5. An important thing to keep in mind is that ngo5 is used as both “I” and “me.” </p>
      </section>
      <section className='questionPage'>
        <TTSBlock index={1} />
      </section>
      <section className='questionPage'>
        <img className="strokeOrderIMG" alt="Stroke Order of Ngo5 我" src="https://media.discordapp.net/attachments/407710496670875661/1125552448430407711/quackko_characters_2.png?width=476&height=476"></img>
        <TextInputQuestion index={0}/>
      </section>
      <section>
        <p className='text'>We know what "I" is in Cantonese now! Next up, "you" in Cantonese!</p>
      </section>
      <section className='questionPage'>
        <TTSBlock index={0} />
      </section>
      <section className='questionPage'>
        <img className="strokeOrderIMG" alt="Stroke Order of Nei 你"src="https://media.discordapp.net/attachments/1046659695345143820/1124602890993025024/quackko_characters_1.png?width=476&height=476"></img>
        <TextInputQuestion index={1}/>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>Now that we know two of the most common subjects, let’s learn a common verb used on a daily basis. </p>
      </section>
      <section>
        <p className='text'>"Want" is an important verb in every language. The word "want" is used to express a need for something.</p>
      </section>
      <section className='questionPage'>
        <TTSBlock index={2} />
      </section>
      <section className='questionPage'>
        <img className="strokeOrderIMG" alt="Stroke Order of 想 Soeng2" src="https://media.discordapp.net/attachments/407710496670875661/1125656568420122744/quackko_characters_3.png?width=476&height=476"></img>
        <TextInputQuestion index={2}/>
      </section>
      <section>
        <p className='text'>Now, let's put what we learned so far into practice!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={2} />
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U1L1

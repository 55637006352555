import React from 'react'
import './Popup.css'
import Button from './Button'
import { Link } from 'react-router-dom'

function PopUp() {

  const images = [
    "https://media.discordapp.net/attachments/1123511458060324955/1128250203426783292/IMG_0390.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1123806639917629520/IMG_0386.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1126329413269717042/IMG_0388.png?width=276&height=276",
    "https://media.discordapp.net/attachments/1123511458060324955/1128250203426783292/IMG_0390.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1123806639917629520/IMG_0386.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1126329413269717042/IMG_0388.png?width=276&height=276",
    "https://media.discordapp.net/attachments/1123511458060324955/1128250203426783292/IMG_0390.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1123806639917629520/IMG_0386.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1126329413269717042/IMG_0388.png?width=276&height=276",
    "https://media.discordapp.net/attachments/1123511458060324955/1128250203426783292/IMG_0390.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1123806639917629520/IMG_0386.png?width=276&height=276", 
    "https://media.discordapp.net/attachments/1123511458060324955/1126329413269717042/IMG_0388.png?width=276&height=276",
  ]

  console.log(Math.floor(Math.random() * 10))

  return (
    <div className='popUpBlock'>
      <h1 className='popUpYay'>YAYY!</h1>
      <img className="popUpQuackko" alt='Quackko Duck is proud of you for reaching the end!' src={images[Math.floor(Math.random() * 10)]}></img>
      <h3 className='popUpCongrats'>You made it to the end of the lesson!</h3>
      <p className='popUpJoinDiscordForHelp' style={{textDecorationColor:'black', color: 'black', fontSize: 'medium'}}>Join our <a href='https://discord.com/invite/p9YUaYkmbH'>discord server</a>, where we can help you personally!</p>
      <div className='popUpButtons'>
        <Link className="link"to="/practice"><button className='popUpButton'>Practice!</button></Link>
        <Link className="link"to="/lessons"><Button boxShadow="0px 4px #d8af34ef" fontSize="2vh" text="Return to Main Page" color="#FFDC76"/></Link>
      </div>
    </div>
  )
}

export default PopUp

import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer-body'>
      <Link to="/"><img className="logo" alt="logo" src="https://media.discordapp.net/attachments/407710496670875661/1095616063443578930/quackko-logo.png"></img></Link>
      <div className="footer-items">
        <ul className='footerList'>
          <Link className="link"to="https://discord.gg/2FqcJNe4Bp"><a><li className='footerItem'>Discord Server</li></a></Link>
          <Link className="link"to="https://beacons.ai/quackko"><a><li className='footerItem'>Socials</li></a></Link>
          <Link className='link' to="/privacyPolicy"><a><li className='footerItem'>Privacy Policy</li></a></Link>
          <a><li className='footerItem'>Contact Us</li></a>
          <a><li className='footerItem'>Cookies Policy</li></a>
        </ul>
      </div>
    </div>
  )
}

export default Footer

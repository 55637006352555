import React from 'react'
import "./ReviewBlock.css"
import StarIcon from '@mui/icons-material/Star';
import Avatar from '@mui/material/Avatar';

function ReviewBlock({marginBottom, name, review, image}) {
  return (
    <div className='background' style={{marginBottom:marginBottom}}>
      <div className='profile'>
        <Avatar className="review-pfp" src=""/>
        <div className='profile-details'>
          <h1 className='name'>{name}</h1>
          <div className='stars'>
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </div>
        </div>
      </div>
      <p className='review'>{review}</p>
    </div>
  )
}

export default ReviewBlock

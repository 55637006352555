import React from 'react'
import './BottomBar.css'
import Button from './Button'
import { Link } from 'react-router-dom'

function BottomBar() {
  return (
    <div className='bottomBar'>

      <div className='bottomBarLeft'>
        <Link className="link"to="/lessons"><Button fontSize="2vh"text="Leave Lesson"color="white"/></Link>
      </div>

      <h4>Scroll to continue to the next section!</h4>

      <div className='bottomBarRight'>
        <Link className="link"to="/lessons"><Button boxShadow="0px 4px #d8af34ef"color="#FFDC76"fontSize="2vh"text="Finish Lesson"/></Link>
      </div>

    </div>
  )
}

export default BottomBar

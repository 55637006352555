import React from 'react'
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion';

function U1L4() {
  return (
    <div className='U1L4'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome!  </span>Today, we will be learning numbers!</center>
      </section>
      <section>
        <p className='text'>In Cantonese, the most important numbers to remember are 1-10.</p>
      </section>
      <section>
        <p className='text'>The numbers 1-3 are the easiest to remember because they are made up of vertical strokes only.</p>
      </section>
      <section>
        <p className='text'>For example, the character for 'one' is one stroke (一). The character for 'two' is two strokes (二). This pattern also applies to 'three,' which is 三.</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={16} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={8}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke order of 一' src='https://media.discordapp.net/attachments/407710496670875661/1137916957153304616/quackko_characters_17.png?width=468&height=468'></img>
        <TextInputQuestion index={10}/>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={17} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={9}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 二' src='https://media.discordapp.net/attachments/407710496670875661/1137918794782744596/quackko_characters_18.png?width=468&height=468'></img>
        <TextInputQuestion index={11}/>        
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={18} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={10}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt="Stroke Order of 三" src='https://media.discordapp.net/attachments/407710496670875661/1137918832233693234/quackko_characters_19.png?width=468&height=468'></img>
        <TextInputQuestion index={12}/>  
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={23} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={11}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 四' src='https://media.discordapp.net/attachments/1046659695345143820/1138005681975332874/quackko_characters_20.png?width=476&height=476'></img>
        <TextInputQuestion index={13}/>  
      </section>
      <section className='questionPage'>
        <TTSBlock index={12}/>
      </section>
      <section>
        <p className='text'>Tip! The character for 'five' is 五. We remember this character by remembering that it is an "h' shape.</p>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 五' src='https://media.discordapp.net/attachments/407710496670875661/1138017503260454923/quackko_characters_21.png?width=468&height=468'></img>
        <TextInputQuestion index={14}/>  
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={19} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={13}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 六' src='https://media.discordapp.net/attachments/407710496670875661/1138020948684046447/quackko_characters_22.png?width=468&height=468'></img>
        <TextInputQuestion index={15}/>  
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={0} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={14}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 七' src='https://media.discordapp.net/attachments/407710496670875661/1138022352060416010/quackko_characters_23.png?width=468&height=468'></img>
        <TextInputQuestion index={16}/>  
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={20} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={15}/>
      </section>
      <section>
        <p className='text'>Tip! The character for 'eight' is 八. We remember this character by remembering that it looks like a mustache.</p>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 八' src='https://media.discordapp.net/attachments/407710496670875661/1138023481578094642/quackko_characters_24.png?width=468&height=468'></img>
        <TextInputQuestion index={17}/>  
      </section>
      <section className='questionPage'>
        <TTSBlock index={16}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 九' src='https://media.discordapp.net/attachments/407710496670875661/1138027240895037500/quackko_characters_25.png?width=468&height=468'></img>
        <TextInputQuestion index={18}/>  
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={22} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={17}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 十' src='https://media.discordapp.net/attachments/407710496670875661/1138028978339328111/quackko_characters_26.png?width=468&height=468'></img>
        <TextInputQuestion index={19}/>  
      </section>
      <section>
        <p className='text'>As mentioned, the most numbers are 1-10. By remembering 1-10, you actually already know 1-99.</p>
      </section>
      <section>
        <p className='text'>Similar to English, there are patterns. For example, what do 60, 70, and 80 have in common? They all start with the base number and end with 'ty.'</p>
      </section>
      <section>
        <p className='text'>In Cantonese, we start with the base number, then end in 十 (sap6). So, 60 would be "six" and "ten" combined. The word for 60 would be 六十 (luk6 sap6).</p>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={20}/>  
      </section>
      <section>
        <p className='text'>There are also plenty of numbers that don't end with a '0.' For example, 11, 84, or 92.</p>
      </section>
      <section>
        <p className='text'>On top of the base number and '10' that comes at the end, we add another number. For example, 94 would be "nine, ten, four." The word for 'four' is 四 (sei3). So, 94 is 九十四 (gau2 sap6 sei3).</p>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={21}/>  
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U1L4

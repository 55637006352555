import TTS from './TTS';
import React from 'react'
import Button from './Button';
import './QuestionBlock.css';
import { useState } from 'react';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import correctAnswer from '../src/audio/correctAnswer.mp3'
import wrongAnswer from '../src/audio/wrongAnswer.mp3'

function QuestionBlock(props) {

  const index = props.index; 

  const answerClick = (isCorrect) => {
    if(isCorrect===false){
      alert(questions[index].explanation)
      new Audio(wrongAnswer).play()
    } else if (isCorrect===true){
      alert("好嘢! Well Done!")
      new Audio(correctAnswer).play()
    }
  }

  return (
    <div className='questionBlock'>
      {false ? (
        <div className='score-section'>You scored 1 out of {questions.length}</div>
      ) : (
        <>
          <div className='question-section'>
            <div className='question-count'>
              <span>PRACTICE</span>
            </div>
            <div className='question-text'>{questions[index].question}</div>
            <br></br>
          </div>
          <div className='answer-section'>
            {questions[index].choices.map((choice)=> 
              <><button className="answerButton" onClick={() => answerClick(choice.isCorrect)}>{choice.answerText + choice.jyutping}</button>
              <button className="ttsButtonMCQ"onClick={() => TTS(choice.answerText)}><VolumeUpRoundedIcon /></button></>
              )}
          </div>
        </>
      )}
    </div>
  )
}

export const questions = [
  {
    question: "Which word means: eleven?", 
    choices:[
      { answerText: "十 ", jyutping: "(sap6)", isCorrect: false, audio: <QuestionBlock />}, 
      { answerText: "十一 ",jyutping: "(sap6 jat1)" ,isCorrect: true }, 
      { answerText: "十二 (sap6 ji6)", isCorrect: false }, 
      { answerText: "二十一 (ji6 sap6 jat1)", isCorrect: false},
    ],  
    explanation: "Incorrect! To form the number eleven, it would be the words ten and one together. The word for ten is 十 (sap6). The word for one is 一 (jat1). So together, the answer is 十一 (sap6 jat1)!"
  }, 
  {
    question: "Which word means: twelve?", 
    choices:[
      { answerText: "十 (sap6)", isCorrect: false}, 
      { answerText: "十一 (sap6 jat1)", isCorrect: false}, 
      { answerText: "十二 (sap6 ji6)", isCorrect: true},
      { answerText: "二十一 (ji6 sap6 jat1)", isCorrect: false},
    ],  
    explanation: "Incorrect! To form the number eleven, it would be the words ten and one together. The word for ten is 十 (sap6). The word for one is 一 (jat1). So together, the answer is 十一 (sap6 jat1)!"
  }, 
  {
    question: "Which sentence translates to: 'I want apples'?", 
    choices:[
      { answerText: "你想 apples.", jyutping: "", isCorrect: false}, 
      {  answerText: "我想 apples.", jyutping: "", isCorrect: true}, 
      { answerText: "Apples 我想.", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect! 我 is the word for 'I' or 'me'! 想 (want) is a verb, so it should go after the subject, I. Apples is the object so it comes after the verb."
  }, 
  {
    question: "Which sentence translates to: 'I eat oranges'?", 
    choices:[
      { answerText: "你食 oranges.", jyutping: "", isCorrect: false}, 
      {  answerText: "我食 oranges.", jyutping: "", isCorrect: true},
      {  answerText: "我想 oranges.", jyutping: "", isCorrect: false}, 
      { answerText: "Oranges 我想.", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect! 我 is the word for 'I' or 'me'! 想 (want) is a verb, so it should go after the subject, I. Apples is the object so it comes after the verb."
  }, 
  {
    question: "What does this sound like?", 
    choices:[
      { answerText: "cat", isCorrect: false}, 
      { answerText: "cut", isCorrect: false }, 
      { answerText: "chuht", isCorrect: true }, 
      { answerText: "kut", isCorrect: false},
    ],  
    explanation: "Incorrect! The 'C' makes a 'ch' sound. 'A' sounds like 'uh' in English."
  },
  {
    question: "Which word means 'want'?", 
    choices:[
      { answerText: "想", jyutping: " (soeng2)", isCorrect: true}, 
      { answerText: "我", jyutping: " (ngo5)", isCorrect: false }, 
      { answerText: "你", jyutping: " (nei5)", isCorrect: false }, 
      { answerText: "食", jyutping: " (sik6)", isCorrect: false},
    ],  
    explanation: "Incorrect! The word for 'want' is 想 (soeng2). For more help, join our Discord server!"
  }, 
  {
    question: "Which sentence means 'I want to eat dim sum'?", 
    choices:[
      { answerText: "我想食 dim sum", jyutping: "", isCorrect: true}, 
      { answerText: "我想 dim sum", jyutping: "", isCorrect: false }, 
      { answerText: "我食 dim sum", jyutping: "", isCorrect: false }, 
      { answerText: "我食想 dim sum", jyutping: "", isCorrect: false},
    ],  
    explanation: "Incorrect! The word for 'want' is 想 (soeng2). Then, 'eat' comes after that to  say ' want to eat.' For more help, join our Discord server!"
  }, 
  {
    question: "Which sentence translates to: 'I go to the market'?", 
    choices:[
      { answerText: "我去 market", jyutping: "", isCorrect: true}, 
      {  answerText: "你去 market", jyutping: "", isCorrect: false},
      {  answerText: "我想 market.", jyutping: "", isCorrect: false}, 
      { answerText: "Market 我想.", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect! 我 is the word for 'I' or 'me'! 去 (heoi3) is the word for 'go' and is a verb so it should go after the subject, I. 'Market' is the object so it comes after the verb."
  }, 
  {
    question: "Which sentence translates to: 'I am Quackko'?", 
    choices:[
      { answerText: "我係 Quackko", jyutping: "", isCorrect: true}, 
      {  answerText: "你係 Quackko", jyutping: "", isCorrect: false},
      {  answerText: "我食 Quackko", jyutping: "", isCorrect: false}, 
      { answerText: "Quackko 我想.", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect! 我 is the word for 'I' or 'me'! 係 (hai6) is the word for 'am' and is a verb so it should go after the subject, I. 'Quackko' is the object so it comes after the verb."
  }, 
  {
    question: "Which is the word for 'am'?", 
    choices:[
      { answerText: "係", jyutping: " (hai6)", isCorrect: true}, 
      {  answerText: "你", jyutping: " (nei5)", isCorrect: false},
      {  answerText: "食", jyutping: " (sik6)", isCorrect: false}, 
      { answerText: "我", jyutping: " (ngo5)", isCorrect: false}
    ],  
    explanation: "Incorrect!"
  }, 
  {
    question: "Which does '你' mean?", 
    choices:[
      { answerText: "want", jyutping: "", isCorrect: false}, 
      {  answerText: "me", jyutping: "", isCorrect: false},
      {  answerText: "you", jyutping: "", isCorrect: true}, 
      { answerText: "hello", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect!"
  }, 
  {
    question: "Which sentence translates to: 'I want 6 of the apples'?", 
    choices:[
      { answerText: "你想五個 apples.", jyutping: "", isCorrect: false}, 
      {  answerText: "我想六個 apples.", jyutping: "", isCorrect: true}, 
      { answerText: "一個 apples 我想.", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect! 我 is the word for 'I' or 'me'! 想 (want) is a verb, so it should go after the subject, I. 6 is 六 and a measure word (個) has to come after the number. Apples is the object so it comes after the verb."
  }, 
  {
    question: "Which phrase translates to: 'Good apple'?", 
    choices:[
      { answerText: "好嘅apple.", jyutping: "", isCorrect: true}, 
      {  answerText: "好apple嘅", jyutping: "", isCorrect: false}, 
      { answerText: "apple嘅好", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect! Because 好 (hou2) is an adjective, 嘅 (ge3) has to come after it so that it can modify the noun, apple."
  }, 
  {
    question: "Which phrase translates to: 'Very sweet apple'?", 
    choices:[
      { answerText: "好甜嘅apple.", jyutping: "", isCorrect: true}, 
      {  answerText: "好apple嘅甜", jyutping: "", isCorrect: false}, 
      { answerText: "apple嘅好甜", jyutping: "", isCorrect: false}
    ],  
    explanation: "Incorrect! Adjectives have to come before 嘅 (ge3) in order to modify the noun, apple. The adjectives in this case are 'very' (好 hou2) and 'sweet' (甜 (tim4). Because 'very' is emphasizing 'sweet,' it has to come before it.Because 好 (hou2) is an adjective, 嘅 (ge3) has to come after it so that it can modify the noun, apple."
  }, 
];

export default QuestionBlock
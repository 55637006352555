import React from 'react'
import BottomBar from './BottomBar'
import TextInputQuestion from './TextInputQuestion'
import PronounceQuestion from './PronounceQuestion'
import Popup from './Popup'

function U0L3() {
  return (
    <div className='U0L3'>
      <BottomBar />
      <section className="welcome">
        <center className='text'>Welcome back to <span className="welcomeQuackko">Quackko!</span></center>
      </section>
      <section>
        <p className='text'>Before we begin, let's do some review!</p>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={3}/>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={0}/>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={1}/>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>During the last lesson, we learned the sounds of the consonants. Now, we will learn the vowels.</p>
      </section>
      <section>
        <img className="mobileIMG"alt='u makes a oo sound' src='https://media.discordapp.net/attachments/407710496670875661/1130698857118957689/High_7.png?width=1002&height=476'></img>
        <p className='text'>In Jyutping, 'u' makes a 'oo' sound. So, if the word is romanized as 'fu' it is pronounced 'foo.' However, this does not apply if it is 'uk' or 'ung.'</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={2}/>
      </section>
      <section>
        <img className="mobileIMG" alt='Uk sounds like Oak' src='https://media.discordapp.net/attachments/407710496670875661/1130698634976043058/High_6.png?width=1002&height=476'></img>
        <p className='text'>As mentioned, the 'u' does not make a 'oo' sound when it is 'uk.' Instead, the 'uk' sounds like the word, oak, in English.</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={3}/>
      </section>
      <section>
        <p className='text'>Moving onto the 'ung' sound. Just like 'uk,' the 'u' doesn't change to a 'oo' sound in this case. 'Ung' actually sounds like 'oh' with a 'ng' sound at the end. 'Ng' is described to be a nasally sound. Most English speakers can mimic this sound by saying the word "sing." The ending sound/feeling that is at the back of your throat is what 'ng' sounds/feels like. Listen to an example in the next section!</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={4}/>
      </section>
      <section>
        <p className='text'>Congrats! We just went over the 'u' vowel. Let's move onto 'a' and 'aa'</p>
      </section>
      <section>
        <img className="mobileIMG" alt="'A' changes to a 'U' sound." src='https://media.discordapp.net/attachments/1046659695345143820/1130663482887520316/High_4.png?width=1002&height=476'></img>
        <p className='text'>In English, 'a' makes an 'ah' sound. In Jyutping, 'a' changes to an 'u.' So, if the Jyutping is 'sam,' it would actually sound like 'sum' in English.</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={5}/>
      </section>
      <section>
        <img className="mobileIMG" alt='aa makes an ah sound' src='https://media.discordapp.net/attachments/407710496670875661/1130699461354590328/High_8.png?width=1002&height=476'></img>
        <p className='text'>The 'aa' sound, however, doesn't change to an 'u.' It sounds like 'aaaaah.' It is a lengthened 'a' sound.</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={6}/>
      </section>
      <section>
        <p className='text'>In Jyutping, 'i' sounds like the letter 'e.' Literally. The sound is like the 'ee' in 'see.'</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={7}/>
      </section>
      <section>
        <p className='text'>As mentioned earlier, 'u' makes a 'oo' sound, but not when it is 'uk' and 'ung.' There is a similar situation with 'i.' It is not 'ee' if it is 'ing' or 'ik.'</p>
      </section>
      <section>
        <p className='text'>'ik' and 'ing' are very straigthforward! In Jyutping, 'ik' sounds like 'ick' in English. 'Ing' also sounds like the 'ing' in English.</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={8}/>
      </section>
      <section>
        <p className='text'>In Jyutping, 'e' is just like 'e' in English. It also sounds like 'eh.'</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={9}/>
      </section>
      <section>
        <p className='text'>When you see 'ei' in Jyutping, it is pronounced as 'aye.' It can also be described as the 'eigh' in 'neigh'</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={10}/>
      </section>
      <section>
        <p className='text'>In Jyutping, 'ou' sounds like 'oh' in English.</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={11}/>
      </section>
      <section>
        <p className='text'>This may be confusing, but 'o' in Jyutping is not pronounced 'oh.' It is actually pronounced like the 'o' in 'on' and 'bot.' Listen to an example in the next section!</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={12}/>
      </section>
      <section>
        <p className='text'>Second to last, 'au.' This sounds somewhat similar to 'oh,' but does not have a strong 'o' sound. Listen to the example on the next section and try to mimic the sound by yourself!</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={13}/>
      </section>
      <section>
        <p className='text'>Lastly, the 'eoi' sound is probably the most confusing for most English speakers. This sound is not used in English. The closest sound would be 'oi.' For example, the 'oi' in 'koi fish.' But, there is also a slight 'ugh' sound in there. Listen to the example in the next section and try pronouncing it!</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={14}/>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U0L3

import React from 'react'
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  return (
    <div className='privacyPolicy'>
      <h1>Privacy Policy</h1>
    </div>
  )
}

export default PrivacyPolicy

import React from 'react'
import './ContactSection.css'
import Button from './Button'
import { Link } from 'react-router-dom'

function ContactSection() {
  return (
    <div className='contactBody'>
      <h1 className='contact-us'>CONTACT US</h1>
      <h1 className='touch'>Get in Touch!</h1>
      <p className='questions'>If you have any questions or concerns, feel free to reach out to us at any time. Our team is dedicated to providing excellent customer support, and we'll do our best to get back to you as soon as possible. Don't hesitate to contact us with any inquiries you may have!</p>
      <Link className='link' to="/contactQuackko"><Button boxShadow="0px 4px #d8af34ef" className="contactButton" fontSize="18px" width="200px"text="Contact Us!" color="#FFDC76"/></Link>
    </div>
  )
}

export default ContactSection

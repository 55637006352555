import React from 'react'
import './ContactPage.css'

function ContactPage() {
  return (
    <div className='contactPage'>
      <div className='emailContent'>
        <form className="contactForm"action="https://formsubmit.co/c4a29760f0fa69417bd8eb68756aae9a" method="POST">
          <input className='contactInput' placeholder="Enter Name" type="text" name="name" required></input>
          <input className='contactInput' placeholder="Enter Email" type="email" name="email" required></input>
          <input className='contactInput'type="text" name="_subject" placeholder='Enter Subject Line'></input>
          <textarea className='contactInput' name="message" placeholder="Details of your problem"></textarea>
          <input className='contactInput' type="file" name="attachment" accept="image/png, image/jpeg"></input>
          <input className='contactInput' type="hidden" name="_next" value="https://yourdomain.co/thanks.html"></input>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  )
}

export default ContactPage

import React from 'react'
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion';

function U2L3() {
  return (
    <div className='U2L2'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome Back!</span></center>
      </section>
      <section>
        <p className='text'>Let's begin with some review!</p>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 靚' src='https://media.discordapp.net/attachments/407710496670875661/1187688278384717834/quackko_characters_30.png?ex=6597cbe7&is=658556e7&hm=e076700b7a7edd6c8dac3d8cf156573e7f91c5bac7a5178beafc30f2d287749f&=&format=webp&quality=lossless&width=468&height=468'></img>
        <TextInputQuestion index={26}/>
      </section>
      <section>
        <p className='text'>The reason why it is "你好靚 (nei5 hou2 leng3)" is because 你 (nei5) is the subject and 好靚 (hou2 leng3) is modifying it. Remember, in the basic grammar structure, the subject comes first. In this case, there is no verb and no object, but an adjective. 
</p>
      </section>
      <section>
        <p className='text'>Following this same pattern, we can describe food. Starting off, we will learn positive descriptions! 
</p>
      </section>
      <section>
        <p className='text'>Instead of 你 (nei5), we will be using 呢個(ni1 go3), which translates to "this" or "this one." Once again, 呢個(ni1 go3) is a general measure word and can be applied to almost anything. 
</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={26}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={24}/>
      </section>
      <section>
        <p className='text'>So, if we want to say something is pretty, it would be 呢個好靚(ni1 go3 hou2 leng3). The only change is the subject. Let’s practice!</p>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 呢' src='https://media.discordapp.net/attachments/407710496670875661/1187712322265960518/quackko_characters_31.png?ex=6597e24c&is=65856d4c&hm=cf920d4674bc384d05bb7438e14e902f873cff7074036cb603140933ca294d69&=&format=webp&quality=lossless&width=468&height=468'></img>
        <TextInputQuestion index={27}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={25}/>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>As we’ve learned in the last lesson, 甜 (tim4) means sweet. Some of the foods we love tend to be sweet!</p>
      </section>
      <section>
        <p className='text'>Now that we know how to say ni go, we can use it form a full sentence. Using the knowledge we just learned, let’s try saying ‘this one is very sweet!’ </p>
      </section>
      <section>
        <p className='text'>But first, let's review 甜 (tim4)! </p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={12} />
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={25}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 甜' src='https://media.discordapp.net/attachments/407710496670875661/1187714013463838851/quackko_characters_32.png?ex=6597e3df&is=65856edf&hm=3e765f117a834030f52bd9c290372fd44dcc16daee5982da942a32998514e241&=&format=webp&quality=lossless&width=468&height=468'></img>
        <TextInputQuestion index={28}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={26}/>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>Let’s learn another common compliment!</p>
      </section>
      <section>
        <p className='text'>Most food we like is yummy, which is 好味 (hou2 mei6) in Cantonese! 味 (mei6) is the word for taste. Once again, 好 (hou2) is coming before 味 (mei6) in order to add emphasis.
</p>
      </section>
      <section>
        <p className='text'>In this case, 好 (hou2) is necessary because 味 (mei6) on its own wouldn’t mean tasty. It just means ‘taste.’
</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={24}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 味' src='https://media.discordapp.net/attachments/407710496670875661/1187715222182567936/quackko_characters_33.png?ex=6597e4ff&is=65856fff&hm=3540a89755b02616368e117a2b82c612a6608984faaa8afa105941bc1350664e&=&format=webp&quality=lossless&width=468&height=468'></img>
        <TextInputQuestion index={29}/>
      </section>
      <section>
        <p className='text'>Now that we know how 味 (mei6), let’s put it into the sentence! 
</p>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={30}/>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U2L3

import TTS from './TTS';
import React from 'react'
import Button from './Button';
import './QuestionBlock.css';
import { useState } from 'react';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import correctAnswer from '../src/audio/correctAnswer.mp3'
import wrongAnswer from '../src/audio/wrongAnswer.mp3'

function PronounceQuestion(props) {

  const index = props.index; 

  const answerClick = (isCorrect) => {
    if(isCorrect===false){
      alert(pronounceQuestions[index].explanation)
      new Audio(wrongAnswer).play()
    } else if (isCorrect===true){
      alert("好嘢! Well Done!")
      new Audio(correctAnswer).play()
    }
  }

  const enableTTS = () => {

    TTS(pronounceQuestions[index].word); 

  }

  return (
    <div className='questionBlock'>
      {false ? (
        <div className='score-section'>You scored 1 out of {pronounceQuestions.length}</div>
      ) : (
        <>
          <div className='question-section'>
            <div className='question-count'>
              <span>PRACTICE</span>
            </div>
            <div className='question-text'>{pronounceQuestions[index].question}</div>
            <br></br>
            <div className='pronunciation'>
          <p className='vocabWord'>{pronounceQuestions[index].word + pronounceQuestions[index].jyutping}</p>
          <button onClick={() => enableTTS()}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
            <br></br>
          </div>
          <div className='answer-section'>
            {pronounceQuestions[index].choices.map((choice)=> 
              <><button className="answerButton" onClick={() => answerClick(choice.isCorrect)}>{choice.answerText}</button>
              </>
              )}
          </div>
        </>
      )}
    </div>
  )
}

export const pronounceQuestions = [
  {
    question: "What should this sound like?", 
    word: "七", 
    jyutping: " (cat1)", 
    choices:[
      { answerText: "cat", isCorrect: false}, 
      { answerText: "cut", isCorrect: false }, 
      { answerText: "chuht", isCorrect: true }, 
      { answerText: "kut", isCorrect: false},
    ],  
    tip: "Reminder: 'C' in Jyutping makes a 'ch' or 'ts' sound!", 
    explanation: "Incorrect! The 'C' makes a 'ch' sound. 'A' sounds like 'uh' in English."
  },
  {
    question: "What should this sound like?", 
    word: "有", 
    jyutping: " (jau5)", 
    choices:[
      { answerText: "yau", isCorrect: true}, 
      { answerText: "joy", isCorrect: false }, 
      { answerText: "jaw", isCorrect: false }, 
      { answerText: "yoooooo", isCorrect: false }
    ],  
    tip: "Reminder: 'C' in Jyutping makes a 'ch' or 'ts' sound!", 
    explanation: "Incorrect! The 'J' changes to the sound of the letter 'Y' instead. Fun Fact: This rule also applies in the German language!"
  }, 
  {
    question: "What should this sound like?", 
    word: "姑", 
    jyutping: " (gu1)", 
    choices:[
      { answerText: "goo", isCorrect: true}, 
      { answerText: "gui", isCorrect: false }, 
      { answerText: "joo", isCorrect: false }, 
      { answerText: "jui", isCorrect: false }
    ],  
    tip: "Reminder: 'U' makes a 'oo' sound! The 'g' still remains as a 'guh' sound.", 
    explanation: "Incorrect! 'U' makes a 'oo' sound! The 'g' still remains as a 'guh' sound."
  },
  {
    question: "What should this sound like?", 
    word: "覆", 
    jyutping: " (fuk1)", 
    choices:[
      { answerText: "foak", isCorrect: true}, 
      { answerText: "fook", isCorrect: false }, 
      { answerText: "koak", isCorrect: false }, 
      { answerText: "joak", isCorrect: false }
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'uk' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! 'U' makes a 'oo' sound! The 'g' still remains as a 'guh' sound."
  },
  {
    question: "Listen to this word that has the 'ung' sound in it!", 
    word: "工", 
    jyutping: " (gung1)", 
    choices:[
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'uk' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! 'U' makes a 'oo' sound! The 'g' still remains as a 'guh' sound."
  }, 
  {
    question: "What should this sound like?", 
    word: "身", 
    jyutping: " (san1)", 
    choices:[
      { answerText: "shun", isCorrect: false}, 
      { answerText: "saan", isCorrect: false }, 
      { answerText: "sand", isCorrect: false }, 
      { answerText: "sun", isCorrect: true }
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'uk' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! The 'a' changes to an 'u.' The 's' still remains as a 's' sound."
  },
  {
    question: "What should this sound like?", 
    word: "山", 
    jyutping: " (saan1)",
    choices:[
      { answerText: "sean", isCorrect: false}, 
      { answerText: "saan", isCorrect: true }, 
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'aa' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! The 'aa' does not change to an 'u.' It sounds like an 'ah' sound. The 's' still remains as a 's' sound."
  },
  {
    question: "What should this sound like?", 
    word: "詩", 
    jyutping: " (si1)",
    choices:[
      { answerText: "seh", isCorrect: false}, 
      { answerText: "see", isCorrect: true }, 
      { answerText: "jee", isCorrect: false}, 
      { answerText: "jeh", isCorrect: false}, 
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'aa' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! The 'i' sounds like 'ee.' The 's' still remains as a 's' sound."
  },
  {
    question: "What should this sound like?", 
    word: "星", 
    jyutping: " (sing1)",
    choices:[
      { answerText: "seeing", isCorrect: false}, 
      { answerText: "sing", isCorrect: true }, 
      { answerText: "seen", isCorrect: false}, 
      { answerText: "jing", isCorrect: false}, 
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'aa' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! The 'i' does not sound like 'ee' in this case. 'Ing' is pronounced the same way it would in English. This sounds really similar to the English word, sing. The 's' still remains as a 's' sound."
  },
  {
    question: "What should this sound like?", 
    word: "石", 
    jyutping: " (sek6)",
    choices:[
      { answerText: "cheek", isCorrect: false}, 
      { answerText: "sehk", isCorrect: true }, 
      { answerText: "jehk", isCorrect: false}, 
      { answerText: "seek", isCorrect: false}, 
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'aa' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! The 'e' sounds like 'eh' in English. The 's' still remains as a 's' sound."
  },
  {
    question: "What should this sound like?", 
    word: "你", 
    jyutping: " (nei5)",
    choices:[
      { answerText: "neigh", isCorrect: true}, 
      { answerText: "nee", isCorrect: false }, 
      { answerText: "nah", isCorrect: false}, 
      { answerText: "noo", isCorrect: false}, 
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'aa' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! The 'ei' sounds like 'aye' in English. The 'n' still remains as a 'n' sound."
  },
  {
    question: "What should this sound like?", 
    word: "好", 
    jyutping: " (hou2)",
    choices:[
      { answerText: "hoo", isCorrect: false}, 
      { answerText: "how", isCorrect: false }, 
      { answerText: "ho", isCorrect: true}, 
      { answerText: "ha", isCorrect: false}, 
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'aa' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! The 'ou' sounds like 'oh' in English."
  },
  {
    question: "Listen to this word that has 'o' in it!", 
    word: "歌", 
    jyutping: " (go1)", 
    choices:[
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'uk' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! 'U' makes a 'oo' sound! The 'g' still remains as a 'guh' sound."
  }, 
  {
    question: "Listen to this word that has 'au' in it!", 
    word: "後", 
    jyutping: " (hau6)", 
    choices:[
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'uk' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! 'U' makes a 'oo' sound! The 'g' still remains as a 'guh' sound."
  }, 
  {
    question: "Listen to this word that has 'eoi' in it!", 
    word: "水", 
    jyutping: " (seoi2)", 
    choices:[
    ],  
    tip: "Reminder: 'U' doesn't make a 'oo' sound in this case! The 'uk' sounds like 'oak.' The 'f' still remains as a 'fuh' sound.", 
    explanation: "Incorrect! 'U' makes a 'oo' sound! The 'g' still remains as a 'guh' sound."
  }, 
  {
    question: "What should this sound like?", 
    word: "食", 
    jyutping: " (sik6)",
    choices:[
      { answerText: "sick", isCorrect: true}, 
      { answerText: "seek", isCorrect: false }, 
      { answerText: "sike", isCorrect: false}, 
      { answerText: "slick", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'ik' sounds like 'ick' in English."
  },
  {
    question: "What should this sound like?", 
    word: "一", 
    jyutping: " (jat1)",
    choices:[
      { answerText: "yuht", isCorrect: true}, 
      { answerText: "jat", isCorrect: false }, 
      { answerText: "jaat", isCorrect: false}, 
      { answerText: "yacht", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'j' sounds like a 'y' in English. The 'a' makes the sound of an 'u.'"
  },
  {
    question: "What should this sound like?", 
    word: "二", 
    jyutping: " (ji6)",
    choices:[
      { answerText: "yeh", isCorrect: false}, 
      { answerText: "jih", isCorrect: false }, 
      { answerText: "jee", isCorrect: false}, 
      { answerText: "yee", isCorrect: true}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'j' sounds like a 'y' in English. The 'i' makes a long 'ee' sound."
  },
  {
    question: "What should this sound like?", 
    word: "三", 
    jyutping: " (saam1)",
    choices:[
      { answerText: "sum", isCorrect: false}, 
      { answerText: "sahm", isCorrect: true }, 
      { answerText: "sam", isCorrect: false}, 
      { answerText: "sham", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'aa' makes a long 'ah' sound."
  },
  {
    question: "What should this sound like?", 
    word: "六", 
    jyutping: " (luk6)",
    choices:[
      { answerText: "luke", isCorrect: false}, 
      { answerText: "loak", isCorrect: true }, 
      { answerText: "luck", isCorrect: false}, 
      { answerText: "lick", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'uk' makes an 'oak' sound."
  },
  {
    question: "What should this sound like?", 
    word: "八", 
    jyutping: " (baat3)",
    choices:[
      { answerText: "but", isCorrect: false}, 
      { answerText: "bat", isCorrect: false }, 
      { answerText: "baht", isCorrect: true}, 
      { answerText: "boot", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'aa' makes a long 'ah' sound."
  },
  {
    question: "What should this sound like?", 
    word: "九", 
    jyutping: " (gau2)",
    choices:[
      { answerText: "but", isCorrect: false}, 
      { answerText: "bat", isCorrect: false }, 
      { answerText: "baht", isCorrect: true}, 
      { answerText: "boot", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'aa' makes a long 'ah' sound."
  },
  {
    question: "What should this sound like?", 
    word: "十", 
    jyutping: " (sap6)",
    choices:[
      { answerText: "soup", isCorrect: false}, 
      { answerText: "saap", isCorrect: false }, 
      { answerText: "sup", isCorrect: true}, 
      { answerText: "sap", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'a' makes an 'uh' sound."
  },
  {
    question: "What should this sound like?", 
    word: "四", 
    jyutping: " (sei3)",
    choices:[
      { answerText: "slay", isCorrect: false}, 
      { answerText: "si", isCorrect: false }, 
      { answerText: "say", isCorrect: true}, 
      { answerText: "see", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'ei' makes an 'aye' sound."
  },
  {
    question: "What should this sound like?", 
    word: "味", 
    jyutping: " (mei6)",
    choices:[
      { answerText: "may", isCorrect: true}, 
      { answerText: "me", isCorrect: false }, 
      { answerText: "meal", isCorrect: false}, 
      { answerText: "way", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'ei' makes an 'aye' sound. The 'm' sounds remains the same!"
  },
  {
    question: "What should this sound like?", 
    word: "甜", 
    jyutping: " (tim4)",
    choices:[
      { answerText: "team", isCorrect: true}, 
      { answerText: "tim", isCorrect: false }, 
      { answerText: "tin", isCorrect: false}, 
      { answerText: "zeam", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'i' makes the sound of the letter 'e.' The 't' sounds remains the same!"
  },
  {
    question: "What should this sound like?", 
    word: "呢", 
    jyutping: " (ni1)",
    choices:[
      { answerText: "nee", isCorrect: true}, 
      { answerText: "nay", isCorrect: false }, 
      { answerText: "neye", isCorrect: false}, 
      { answerText: "mee", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'i' makes the sound of the letter 'e.' The 'n' sounds remains the same!"
  },
  {
    question: "What should this sound like?", 
    word: "點心", 
    jyutping: " (dim2 sam1)",
    choices:[
      { answerText: "deem sim", isCorrect: false}, 
      { answerText: "deem sam", isCorrect: false }, 
      { answerText: "deem sum", isCorrect: true}, 
      { answerText: "them sum", isCorrect: false}, 
    ],  
    tip: "Reminder: 'ik' sounds like 'ick' in English.", 
    explanation: "Incorrect! The 'i' makes the sound of the letter 'e.' The singular 'a' is pronounced the way 'u' would be in English. This is why 'sam' in Jyutping sounds more like 'sum' in English. The 'd' and 's' sounds remains the same!"
  },
];



export default PronounceQuestion

import React from 'react'
import BottomBar from './BottomBar'
import TextInputQuestion from './TextInputQuestion'
import Popup from './Popup'


function U0L4() {
  return (
    <div className='U0L4'>
      <BottomBar />
      <section className="welcome">
        <center className='text'>Welcome back to <span className="welcomeQuackko">Quackko!</span></center>
      </section>
      <section>
        <p className='text'>In this lesson, we will learn more about strokes! Learning about strokes will help you write and use the stroke keyboard!</p>
      </section>
      <section>
        <img className="keyboardIMG"src="https://media.discordapp.net/attachments/407710496670875661/1093354730798977114/IMG_2931.png?width=850&height=550"/>
        <p className='text'>Recap! This is what the stroke keyboard looks like. Each key is a stroke and the * key is a "wild card" key. If there is a stroke you are unsure of, you can use this wild card key.</p>
      </section>
      <section>
        <p className='text'>There are many strokes so it would be ridiculous to have all of them on one keyboard. Instead, the strokes are categorized into the 5 key strokes on the keyboard.</p>
      </section>
      <section>
        <p className='text'>When using the stroke keyboard, you are basically typing out the strokes instead of physically writing them.</p>
      </section>
      <section>
        <img className="keyboardIMG" alt="the first key on the cantonese stroke keyboard"src='https://media.discordapp.net/attachments/407710496670875661/1133567802310144150/quackko_characters_4.png?width=476&height=476'></img>
        <p className='text'>The first key is represents all the horizontal and rising strokes. In the image above, there are examples of these strokes. So if those strokes are in a character you are trying to type, click the first key.</p>
      </section>
      <section>
        <img className='keyboardIMG' alt='the second key on the cantonese stroke keyboard' src='https://media.discordapp.net/attachments/407710496670875661/1133572007921995796/quackko_characters_5.png?width=476&height=476'></img>
        <p className='text'>The second key represents all the vertical strokes. When there is a vertical stroke that looks like the ones in the image, click the second key.</p>
      </section>
      <section>
        <img className="keyboardIMG" alt="the third key on the cantonese stroke keyboard"src='https://media.discordapp.net/attachments/407710496670875661/1133578912295817266/quackko_characters_8.png?width=476&height=476'></img>
        <p className='text'>The third key represents all the strokes that fall to the left. When there is a stroke that looks like the ones in the image, click the third key.</p>
      </section>
      <section>
        <img className='keyboardIMG' alt="the fourth key on the cantonese stroke keyboard" src='https://media.discordapp.net/attachments/407710496670875661/1133578858302545962/quackko_characters_9.png?width=476&height=476'></img>
        <p className='text'>The fourth key represents all the strokes that fall to the right or is a dot. It may be hard to tell in the picture, but the dots are typically smaller than the other strokes.</p>
      </section>
      <section>
        <img className='keyboardIMG' alt="the fifth key on the cantonese stroke keyboard" src='https://media.discordapp.net/attachments/407710496670875661/1133578880356192368/quackko_characters_10.png?width=476&height=476'></img>
        <p className='text'>The fifth key represents strokes that turn. These may be harder to identify because it looks like the other strokes combined. This stroke is categorized as a "turn" is because it is done in one stroke, but with many turns in between.</p>
      </section>
      
      <section>
        <img className="writingIMG"src="https://media.discordapp.net/attachments/407710496670875661/1093352684775555172/IMG_2928.png?width=850&height=550"></img>
        <p className='text'>As for the handwriting keyboard, it is much easier as you just need to use your finger and write out what you see.</p>
      </section>
      <section>
        <p className='text'>For writing, regardless of which keyboard is being used, stroke order matters. With the handwriting keyboard, it is usually more flexible with the stroke order, but the stroke keyboard is usually really strict with it since it has to be specific.</p>
      </section>
      <section>
        <p className='text'>The general rule is to write from top to bottom and left to right.</p>
      </section>
      <section className='questionPage'>
        <img className="strokeOrderIMG" alt="Stroke Order of 三 saam1" src="https://media.discordapp.net/attachments/407710496670875661/1137918832233693234/quackko_characters_19.png?width=468&height=468"></img>
        <TextInputQuestion index={4}/>
      </section>
      <section>
        <p className='text'>Moving onto more complex characters, horizontal strokes come first, then the vertical stroke goes through. For example, the 十 character.</p>
      </section>
      <section>
        <p className='text'>We suggest using <a href='https://bihua.bmcx.com/e68891__bihuachaxun/'>this website</a> if you want to search up the stroke order of a character. This website shows the strokes for the stroke keyboard as well!</p>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U0L4

import React from 'react'
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion';

function U2L1() {
  return (
    <div className='U2L1'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome Back!</span></center>
      </section>
      <section>
        <p className='text'>Let's begin with some review!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={2} />
      </section>
      <section>
        <p className='text'>Using our knowledge of numbers, we can specify how many apples we want.</p>
      </section>
      <section>
        <p className='text'>The direct translation of '3 apples' would be '三 apples. (saam1 apples)'</p>
      </section>
      <section>
        <p className='text'>In Cantonese, we also use measure words, which are words that are used with a number to specify an amount of something.</p>
      </section>
      <section>
        <p className='text'>The general measure word is 個 (go3). This measure word can mostly be applied to anything and is similar to 'of the' in English. For example, '3 of the apples' is like '三個 apples' in Cantonese.</p>
      </section>
      <section>
        <p className='text'>Using this knowledge, let's add a quantity to the sentence we did at the start of the lesson.</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={11} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={18}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={19}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={20}/>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 個' src='https://media.discordapp.net/attachments/452708771119235072/1138277014298693642/quackko_characters_28.png?width=476&height=476'></img>
        <TextInputQuestion index={22}/>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={23}/>
      </section>
      <section>
        <p className='text'>When 2 is used with a measure word, it is no longer 二 (ji6). Instead, it changes to 两 (loeng5).</p>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 两' src='https://media.discordapp.net/attachments/1046659695345143820/1138326809323245608/quackko_characters_29.png?width=468&height=468'></img>
        <TextInputQuestion index={25}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={21}/>
      </section>
      <section className='questionPage'>
        <TextInputQuestion index={24}/>
      </section>
      <section className='questionPage'>
        <TTSBlock index={22}/>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U2L1

import React from 'react';

import './App.css';
import Hero from './Hero';
import Reviews from './Reviews';
import ContactSection from './ContactSection';
import Footer from './Footer';

function Landing() {
  return (
    <div id='Landing' className="Landing">
      <div className="app_body">
        <Hero />
        <Reviews />
        <ContactSection />
        <Footer/>
      </div>
    </div>
  );
}

export default Landing;

import React from 'react'
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion';

function U1L2() {
  return (
    <div className='U1L2'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome!  </span>Today, we will be learning some more common verbs!</center>
      </section>
      <section>
        <p className='text'>One of Quackko's favorite activities is eating! So, let's learn how to say 'eat' in Cantonese!</p>
      </section>
      <section>
        <p className='text'>The word for eat is 食 (sik6). Using your knowledge of Jyutping, try to guess what this would sound like.</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={15} />
      </section>
      <section className='questionPage'>
        <TTSBlock index={3} />
      </section>
      <section className='questionPage'>
        <img className="strokeOrderIMG" alt="Stroke Order of 食 (sik6)" src='https://media.discordapp.net/attachments/407710496670875661/1136818022305247253/quackko_characters_12.png?width=476&height=476'></img>
        <TextInputQuestion index={5}/> 
      </section>
      <section>
        <p className='text'>Now, let's practice using it in a sentence!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={3}/>
      </section>
      <section>
        <p className='text'>Just like English, multiple verbs can be used together in a sentence. For example, instead of just, "I eat apples," it can be "I want to eat apples."</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={5}/>
      </section>
      <section>
        <p className='text'>Now that we remember what 'want' is in Cantonese, let's try to put 'want' and 'eat' in the same sentence.</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={6}/>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>Another one of Quackko's favorite activity is going out! The word for 'go' in Cantonese is 去 (heoi3).</p>
      </section>
      <section>
        <p className='text'>Reminder, the 'eoi' sound is similar to 'oi' in 'koi fish,' but with a slight 'ugh' sound. Do your best to pronounce 去 (heoi3) in the next section!</p>
      </section>
      <section className='questionPage'>
        <TTSBlock index={4} />
      </section>
      <section className='questionPage'>
        <img className="strokeOrderIMG" alt="Stroke Order of 去 (heoi3)" src='https://media.discordapp.net/attachments/407710496670875661/1136957013872623667/quackko_characters_13.png?width=476&height=476'></img>
        <TextInputQuestion index={6}/> 
      </section>
      <section>
        <p className='text'>Now, let's practice using it in a sentence!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={7}/>
      </section>
      <section>
        <p className='text'>Another common verb is 係 (hai6). It means 'am' or 'is.'</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={8}/>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U1L2

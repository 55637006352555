import React from 'react'
import './LessonsPage.css'
import ReviewBlock from './ReviewBlock'
import LessonBlock from './LessonBlock'
import {Helmet} from 'react-helmet-async'

function LessonsPage() {
  return (
    <>
    <Helmet>
      <title>Lessons</title>
      <meta  name='description' content='Start learning Cantonese for free as an English speaker today!'/>
      <link rel="canonical" href='/lessons' />
    </Helmet>
    <div className='lessonsPage'>
      <div className='lessonsHeader'>
        <h1 className='welcome-msg'>Welcome!</h1>
        <p className='msg'>We have curated a list of lessons for you to get started in learning Cantonese. This page is constantly being updated! This is a soft launch of our site so many things are not completed yet!</p>
      </div>
      <div className='lessonsBody'>
        <div className='sideBar'>
          <a href="https://discord.gg/2FqcJNe4Bp"><img className = 'discordSide' src="https://media.discordapp.net/attachments/407710496670875661/1138039567677657169/quackko_characters_27.png?width=468&height=468"></img></a>
        </div>
        
        <div className='lessonBlocks'>
          <LessonBlock />
        </div>
      </div>
    </div>
    </>
  )
}

export default LessonsPage

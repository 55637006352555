import React from 'react';
import './TextInputQuestion.css'
import TTS from './TTS';
import TTSButton from './TTSBlock';
import correctAnswer from '../src/audio/correctAnswer.mp3'
import wrongAnswer from '../src/audio/wrongAnswer.mp3'

function TextInputQuestion(props) {

  const index = props.index; 

  function handleSubmit(e) {
    e.preventDefault();

    var inputValue = document.getElementById("textInputAnswer"+index).value; 

    if(inputValue !== textInputQuestions[index].answer){
      new Audio(wrongAnswer).play()
      alert(textInputQuestions[index].explanation)
    } else if (inputValue === textInputQuestions[index].answer){
      new Audio(correctAnswer).play()
      alert("好嘢! Well Done!")
    }

    document.forms['textAnswerInputBlock'].reset();
  
  }

  return (
    <div className='textInputQuestions'>
      <div className='question-section'>
            <div className='question-count'>
              <span>PRACTICE</span>
            </div>
            <div className='question-text'>{textInputQuestions[index].question}</div>
            <div className='instructionsContainer'>
            <p className='textInputInstructions'>{textInputQuestions[index].instructions}</p>
            </div>
          </div>
          <div className='answerArea'>
            <form onSubmit={handleSubmit} className='textAnswerInputBlock' id="textAnswerInputBlock">
              <input id={"textInputAnswer"+index} className="textInputAnswer"placeholder='Type Answer'></input>
              <button className="textInputSubmit"type="submit">Check Answer</button>
            </form>
          </div>
    </div>
  )
}

export const textInputQuestions = [
  {
    question: "What is 'I' in Cantonese? Input the character.", 
    answer: "我", 
    instructions: "Using your input of choice, try to input the correct character.",
    explanation: "Explanation"
  }, 
  {
    question: "What is 'you' in Cantonese? Input the character.", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "你", 
    explanation: "Explanation"
  }, 
  {
    question: "What is 'want' in Cantonese? Input the character.", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "想", 
    explanation: "Explanation"
  }, 
  {
    question: "Which tone is 'Cat1' in?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "1", 
    explanation: "Incorrect! The number '1' after 'Cat' indicates that it is in the first tone."
  }, 
  {
    question: "Try inputting this character!", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "三", 
    explanation: "Incorrect! You may have selected a character that looked like 三, but wasn't actually it. For more help, join our discord server!"
  }, 
  {
    question: "What is 'eat' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "食", 
    explanation: "Incorrect! You may have selected a character that looked like 食, but wasn't actually it. For more help, join our discord server!"
  }, 
  {
    question: "What is 'go' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "去", 
    explanation: "Incorrect! If you used the stroke keyboard, you may have gotten the stroke order wrong. Remember that the general rule is to start from the top and work your way to the bottom. Horizontal strokes go before vertical ones. Horizontal strokes goes straight through after writing the vertical stroke. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'am' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "係", 
    explanation: "Incorrect! If you used the stroke keyboard, you may have gotten the stroke order wrong. Remember that the general rule is to start from the top and work your way to the bottom. Horizontal strokes go before vertical ones. Horizontal strokes goes straight through after writing the vertical stroke. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "Translate: '我係 Quackko'", 
    instructions: "Translate the sentence into English. Use proper English grammar and make sure that the answer doesn't end in unnecessary spaces or punctuation. If the sentence doesn't have a period, don't include a period.",
    answer: "I am Quackko",   
    explanation: "Incorrect! If you used the stroke keyboard, you may have gotten the stroke order wrong. Remember that the general rule is to start from the top and work your way to the bottom. Horizontal strokes go before vertical ones. Horizontal strokes goes straight through after writing the vertical stroke. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'good' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "好", 
    explanation: "Incorrect! If you used the stroke keyboard, you may have gotten the stroke order wrong. Remember that the general rule is to start from the top and work your way to the bottom. Horizontal strokes go before vertical ones. Horizontal strokes goes straight through after writing the vertical stroke. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'one' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "一", 
    explanation: "Incorrect! For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'two' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "二", 
    explanation: "Incorrect! For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'three' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "三", 
    explanation: "Incorrect! For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'four' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "四", 
    explanation: "Incorrect! For characters that look like a box, you should start with the frame, then write the strokes inside, and close it off at the bottom. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'five' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "五", 
    explanation: "Incorrect! If you used the stroke keyboard, you may have gotten the stroke order wrong. Remember that the general rule is to start from the top and work your way to the bottom. You should also move from left to right. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'six' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "六", 
    explanation: "Incorrect! If you used the stroke keyboard, you may have gotten the stroke order wrong. Remember that the general rule is to start from the top and work your way to the bottom. You should also move from left to right. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'seven' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "七", 
    explanation: "Incorrect! You may have gotten the stroke order wrong. Horizontal strokes always start first before other strokes go straight through from the top. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'eight' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "八", 
    explanation: "Incorrect! You may have gotten the stroke order wrong. You should go from left to right. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'nine' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "九", 
    explanation: "Incorrect! You may have gotten the stroke order wrong. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is 'ten' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "十", 
    explanation: "Incorrect! You may have gotten the stroke order wrong. Remember that horizontal strokes go first, then vertical strokes go straight through. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is '90' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct characters.",
    answer: "九十", 
    explanation: "Incorrect! The base number comes first. In this case, it is 9 or 九 (gau2). Then, a 10 (十 sap6) comes after. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is '79' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct characters.",
    answer: "七十九", 
    explanation: "Incorrect! The base number comes first. In this case, it is 7 or 七 (cat1). Then, a 10 (十 sap6) comes after. 79 doesn't end in '0,' so we have to add another number after the '7' and '10.' This number ends in 9 so we add that in the end. Together, it is 七十九 (cat1 sap6 gau2). For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is the general word ('of the') in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "個", 
    explanation: "Incorrect! Generally, you work from left to right, and top to bottom. Remember that horizontal strokes go first, then vertical strokes go straight through. For characters that look like a box, you should start with the frame, then write the strokes inside, and close it off at the bottom. For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is '7 of the' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "七個", 
    explanation: "Incorrect! When using measure words, it is used in combination with a number. The number comes before the measure word. For more personal help and explanations, join our discord server!"
  }, 
  {
    question: "What is '2 of the' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "两個", 
    explanation: "Incorrect! When using 2 as a measure word, it has to be 两 (leong5) instead of 二 (ji6). For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is '2' when it is used with a measure word in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "两", 
    explanation: "Incorrect! Generally, you work from left to right, and top to bottom. For characters that look like a box, you should start with the frame, then write the strokes inside. For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is 'you are very pretty' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "你好靚", 
    explanation: "Incorrect! Generally, you work from left to right, and top to bottom. For characters that look like a box, you should start with the frame, then write the strokes inside. For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is 'this one is very pretty' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "呢個好靚", 
    explanation: "Incorrect! Generally, you work from left to right, and top to bottom. For characters that look like a box, you should start with the frame, then write the strokes inside. For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is 'this one is very sweet' in Cantonese? (Hint: 呢個好靚 means 'this one is very pretty')", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "呢個好甜", 
    explanation: "Incorrect! Generally, you work from left to right, and top to bottom. For characters that look like a box, you should start with the frame, then write the strokes inside. For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is 'yummy' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "好味", 
    explanation: "Incorrect! Generally, you work from left to right, and top to bottom. For characters that look like a box, you should start with the frame, then write the strokes inside. For more personal help and explanations, join our discord server!"
  },  
  {
    question: "What is 'this one is yummy' in Cantonese?", 
    instructions: "Using your input of choice, try to input the correct character.",
    answer: "呢個好味", 
    explanation: "Incorrect! Remember that 味 cannot be on its own in this case. 好 has to come before it because 味 (mei6) itself means 'taste.' 好 (good) modifies 味 so that it becomes 'good taste.' For more personal help and explanations, join our discord server!"
  },  
];

export default TextInputQuestion

import React from 'react'
import './Button.css'

function Button({text, color, width, fontSize, marginRight, boxShadow}) {
  return (
    <div className="button">
      <button style={{boxShadow: boxShadow, backgroundColor: color, width: width, fontSize: fontSize, marginRight: marginRight}}>{text}</button>
    </div>
  )
}

export default Button

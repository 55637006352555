function TTS(TTSText) {

  if ('speechSynthesis' in window) {
  var synthesis = window.speechSynthesis;

  // Regex to match all Cantonese language tags e.g yue-HK, zh-HK
  var langRegex = /-HK$/i;

  // Get the available voices and filter the list to only have Cantonese speakers
  var voices = synthesis
    .getVoices()
    .filter((voice) => langRegex.test(voice.lang));

  // Log the properties of the voices in the list
  voices.forEach(function (voice) {
    console.log({
      name: voice.name,
      lang: voice.lang,
      uri: voice.voiceURI,
      local: voice.localService,
      default: voice.default,
    });
  });

  var text = TTSText; 
  const utterance = new SpeechSynthesisUtterance(text);    
  utterance.rate = 0.6;
  utterance.voice = voices[0]; 
  console.log(utterance.lang);
  speechSynthesis.speak(utterance);
} else {
  alert('Text-to-speech not supported.');
}

}

export default TTS

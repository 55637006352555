import React from 'react'
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion';

function U3L1() {
  return (
    <div className='U2L2'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome Back!</span></center>
      </section>
      <section>
        <p className='text'>Let's begin with some review!</p>
      </section>
      <section className='questionPage'>
        <PronounceQuestion index={27}/>
      </section>
      <section>
        <p className='text'>點心 (dim2 sam1) is not a single dish, but a type of meal. Typically,  點心 (dim2 sam1) consists of small plates of Chinese/Cantonese dishes, accompanied by tea. 
</p>
      </section>
      <section>
        <p className='text'>點心 (dim2 sam1) dishes are usually fried, baked, or steamed. For example, there are baked BBQ Pork Buns (叉烧包 caa1 siu1 baau1), and also steamed ones. 
</p>
      </section>
      <section>
        <p className='text'>When it comes to 點心 (dim2 sam1), there are primarily two different ways to order it.  
</p>
      </section>
      <section>
        <p className='text'>The first method of ordering 點心 (dim2 sam1) is through a paper menu/order form. 
</p>
      </section>
      <section>
      <img className="writingIMG" alt='Dim2 Sam1 (dim sum) 點心 paper menu' src='https://media.discordapp.net/attachments/407710496670875661/1189423246060290108/quackko_characters_35.png?ex=659e1bb8&is=658ba6b8&hm=dbcb670c9445a3c8230e08232ec8bb96672e59e1f646e7410bb5d1b2453801e0&=&format=webp&quality=lossless&width=468&height=468'></img>
      <br></br>
      Photo: Keith Tam's Medium Article
        <p className='text'>Restaurants that use this method will give you a paper menu, usually with both the Chinese names and the English names of the items. Then, in the boxes next to them, you write how many you would like to order. A waiter will come to grab the menu once you are done. 
</p>
      </section>
      <section>
        <p className='text'>The second method of ordering 點心 (dim2 sam1) is through carts. 
</p>
      </section>
      <section>
      <img className="mobileIMG" alt='Ladies typically walk around pushing carts with the 點心 (dim2 sam1) dishes inside.' src='https://media.discordapp.net/attachments/407710496670875661/1189421814233960448/High_9.png?ex=659e1a62&is=658ba562&hm=11890f8b6ca828abfc7770ababce7e4e47efa33322d3b5930708bc201b952eb4&=&format=webp&quality=lossless&width=1002&height=476'></img>
      <br></br>
      Photo: SCMP and welcometochinatown.com
        <p className='text'>Ladies typically walk around pushing carts with the 點心 (dim2 sam1) dishes inside. Once you tell them what you want, they will stamp a paper on your table. Each stamp represents what you ordered. 
</p>
      </section>
      <section>
        <p className='text'>When you are ready to pay, a waiter (typically male) will pick up this paper, calculate the price at the cash register, and come pick with the bill/receipt.
</p>
      </section>
      <section>
        <p className='text'>The benefit of this method is that you can see what the dishes look like and if you think you’d like them.  
</p>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
      <img className="writingIMG" alt='Each  Dim Sum 點心 (dim2 sam1) dish consists of 3-4 pieces.' src='https://media.discordapp.net/attachments/407710496670875661/1189419498588098620/quackko_characters_34.png?ex=659e183a&is=658ba33a&hm=fda0b9e5b4208f5f60adb2b1bb7abcf6fda405f8a2788ffacae33f07521e61bc&=&format=webp&quality=lossless&width=468&height=468'></img>
        <p className='text'>Typically, each 點心 (dim2 sam1) dish consists of 3-4 pieces. Each dish is meant to be shared with the people you are eating with. Common in Chinese culture, many people share dishes that are typically placed in the middle of the table.  
</p>
      </section>
      <section>
        <p className='text'>In the next lesson, we will learn the common 點心 (dim2 sam1) dishes! 
</p>
      </section>
      
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U3L1

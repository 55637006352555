import React from 'react'
import TTS from './TTS'
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import './JyutpingTones.css'

function JyutpingTones() {

  const enableTTS = () => {

    TTS(); 

  }

  return (
    <div className='jyutpingTones'>
      <div className='jyutpingExamplesTTS'>
        <div className="toneExample">
          <p className="toneExampleWord"style={{fontSize:"30px", lineHeightL: "-100px"}}>詩 si1</p>
          <button className='toneExampleButton' onClick={() => TTS("詩")}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
        <div className="toneExample">
          <p className="toneExampleWord"style={{fontSize:"30px", lineHeightL: "-100px"}}>史 si2</p>
          <button className='toneExampleButton' onClick={() => TTS("史")}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
        <div className="toneExample">
          <p className="toneExampleWord"style={{fontSize:"30px", lineHeightL: "-100px"}}>試 si3</p>
          <button className='toneExampleButton' onClick={() => TTS("試")}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
        <div className="toneExample">
          <p className="toneExampleWord"style={{fontSize:"30px", lineHeightL: "-100px"}}>時 si4</p>
          <button className='toneExampleButton' onClick={() => TTS("時")}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
        <div className="toneExample">
          <p className="toneExampleWord"style={{fontSize:"30px", lineHeightL: "-100px"}}>市 si5</p>
          <button className='toneExampleButton' onClick={() => TTS("市")}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
        <div className="toneExample">
          <p className="toneExampleWord"style={{fontSize:"30px", lineHeightL: "-100px"}}>事 si6</p>
          <button className='toneExampleButton' onClick={() => TTS("事")}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
      </div>
    </div>
  )
}

export default JyutpingTones

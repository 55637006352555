import React from 'react'
import './Header.css'
import Button from './Button';
import { Link } from 'react-router-dom'
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from './firebaseApp'; 
import { FirebaseApp } from 'firebase/app';
import { signOut } from 'firebase/auth';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
  const [user, loading] = useAuthState(auth);
  const handleLogout =() => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
    }

  const [toggleMenu, setToggleMenu] = useState(true); 
  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }
  return (
    <div className="header">

      <div className="header__left">
        <Link className="link" to="/"><img className="logo" alt="logo" src="https://media.discordapp.net/attachments/407710496670875661/1095616063443578930/quackko-logo.png"></img></Link>
        {user && (
          <div className='menuIcon'>
          <MenuIcon className="menuIcon"onClick={toggleNav}fontSize='large'/>
          </div>
        )}
      </div>

      {user && (
      <div className="nav-items">
        {toggleMenu && (
          <ul className="nav-items">
          <Link className="link" to="/lessons"><li className='nav-item'>Lessons</li></Link>
          <Link className="link" to="/practice"><li className='nav-item'>Practice</li></Link>
          <Link className="link" to="https://discord.gg/2FqcJNe4Bp"><li className='nav-item'>Join our Discord</li></Link>
        </ul>
        )}
      </div>
      )}

      {!user && (
        <>
        <div></div>
        <div></div>
        </>
      )}

      {!user && (
        <div className="header__right">
          <Link className="link" to="/auth/login"><Button marginRight="10px" fontSize="15px" className="signIn"text="Sign In" color="whitesmoke"/></Link>
          <Link className="link" to="/auth/signup"><Button boxShadow="0px 4px #d8af34ef" fontSize="15px" className="signIn"text="Create Account" color="#FFDC76"/></Link>
        </div>
      )}

      {user && (
        <>
        <div className="header__right">
          <a onClick={handleLogout}><Button text="Log Out" /></a>
        </div>
      </>
      )}

    </div>
  )
}

export default Header

import React from 'react'
import './Reviews.css'
import ReviewBlock from './ReviewBlock'

function Reviews() {
  return (
    <div className="body">
      <h1 className='reviews-h1'>OUR REVIEWS</h1>
      <p className='community-title'>Our community loves <span className="quackko-span"style={{color: "#FFBE00"}}>Quackko!</span></p>
      <div className='reviews'>
        <ReviewBlock marginBottom="25px" name="Anonymous User" review="As an English speaker, trying to learn more Cantonese has been hard because there are not as many resources out there compared to other languages. This felt like the perfect solution and has been part of my language learning routine since!" />
        <ReviewBlock marginBottom="25px" name="Jacquelyn Nguyen" review="I love Quackko's fun and informational lessons that help me learn without making me feel like I'm trying too hard. It helps me understand grammar and structure so easily that it's almost become second-nature. Quackko is such a great resource for learning Cantonese and would strongly recommend it!" />
        <ReviewBlock name="Anonymous User" review="Learning about the Cantonese language and culture would not have been as easy as it is now without Quackko! Quackko focuses on topics that are significant to Cantonese culture, which helped me use Cantonese in those real-life situations." />
      </div>
    </div>
  )
}

export default Reviews

import React from 'react'
import { Link } from 'react-router-dom'
import './Login.css'
import { auth } from './firebaseApp';
import { useState } from 'react';
import {signInWithEmailAndPassword } from "firebase/auth";


export default function Login() {


  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const signIn = () => {

  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  }

  return (
    <div className='loginPage'>
      <div className='top-logo'>
      <Link to="/"><img className="logo" alt="logo" src="https://media.discordapp.net/attachments/407710496670875661/1095616063443578930/quackko-logo.png"></img></Link>
      </div>
      <p>Login and improve your Cantonese today!</p>
      <div>
        <input className="inputField" type={"email"} placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)}/>
        <input className="inputField" type={"password"} placeholder='Enter Password' onChange={(e) => setPassword(e.target.value)}/>
        <div className='signInButton'>
          <Link className="link"to="/lessons"><button style={{boxShadow:"0px 4px #d8af34ef"}}className="signIn" onClick={signIn}>Sign In</button></Link>
        </div>
        <div className='noAcc'>
          <p>Don't have an account? <span><Link to="/auth/signup">Sign Up!</Link></span></p>
        </div>
      </div>
      <div className='loginPage1'>

      </div>
    </div>
  )
}


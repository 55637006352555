import React from 'react'
import QuestionBlock from './QuestionBlock'
import TextInputQuestion from './TextInputQuestion'
import TTSBlock from './TTSBlock'
import Popup from './Popup'; 
import BottomBar from './BottomBar'
import PronounceQuestion from './PronounceQuestion';

function U2L2() {
  return (
    <div className='U2L2'>
      <BottomBar />
      <section className="welcome">
        <center className='text'><span className="welcomeQuackko">Welcome Back!</span></center>
      </section>
      <section>
        <p className='text'>Let's begin with some review!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={2} />
      </section>
      <section>
        <p className='text'>Now, let's review what we've learned about quantities!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={11} />
      </section>
      <section>
        <p className='text'>Great Work! However, we can make these sentences more descriptive!</p>
      </section>
      <section>
        <p className='text'>When using adjectives in Cantonese, it follows this grammar structure: [adjective] + 嘅 + [noun].</p>
      </section>
      <section>
        <img className="writingIMG" alt='Join our Discord to practice and study Cantonese with others' src='https://media.discordapp.net/attachments/1133541614522998794/1135532968471969802/IMG_0414.png?width=468&height=468'></img>
        <p className='text'>Languages were made for communication! Join our <a href='https://discord.com/invite/p9YUaYkmbH'>Discord Server</a> to practice using conversational Cantonese with others! You can also ask for more personal help from our team and community itself!</p>
      </section>
      <section>
        <p className='text'>As we've learned before, "good" in Cantonese is 好 (hou2). This can be used as an adjective!</p>
      </section>
      <section>
        <p className='text'>Let's try using it to modify a noun!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={12} />
      </section>
      <section>
        <p className='text'>Additionally, 好 (hou2) can be used with other adjectives to add emphasis. For example, we can say 好甜 (hou2 tim4), which means "very sweet." So, this shows that 好 can also mean "very."</p>
      </section>
      <section>
        <p className='text'>Let's try using it to modify "apple" again!</p>
      </section>
      <section className='questionPage'>
        <QuestionBlock index={13} />
      </section>
      <section>
        <p className='text'>Knowing how to use 好 (hou2) will also help us compliment others!</p>
      </section>
      <section>
        <p className='text'>As mentioned before, 好 (hou2) can be used to add emphasis. A common compliment is: 好靚 (hou2 leng3), which means "very pretty."</p>
      </section>
      <section>
        <p className='text'>Let's put this compliment into a proper sentence!</p>
      </section>
      <section className='questionPage'>
        <TTSBlock index={23}/>
      </section>
      <section>
        <p className='text'>Using the same sentence, let's try to write/type it! Once again, if writing/typing isn't your goal, you may skip it or practicing pronouncing it again!</p>
      </section>
      <section className='questionPage'>
        <img className='strokeOrderIMG' alt='Stroke Order of 靚' src='https://media.discordapp.net/attachments/407710496670875661/1187688278384717834/quackko_characters_30.png?ex=6597cbe7&is=658556e7&hm=e076700b7a7edd6c8dac3d8cf156573e7f91c5bac7a5178beafc30f2d287749f&=&format=webp&quality=lossless&width=468&height=468'></img>
        <TextInputQuestion index={26}/>
      </section>
      <section className='questionPage'>
        <Popup />
      </section>
    </div>
  )
}

export default U2L2

import React from 'react'
import './Hero.css'
import Button from './Button'
import { Link } from 'react-router-dom'

function Hero() {
  return (
    <div className="hero">
      <div className="hero-left">
        <h1 className='hero-title'>Learn Cantonese for completely <span className="free-span"style={{color: "#FFDC76"}}>free!</span></h1>
        <h4 className='subtext'>Explore a vibrant language and culture!</h4>
        <div className="heroButton">
        <Link className="link" to="/auth/signup"><Button fontSize="20px"marginRight="20px" text="Sign Up!" width= "180px" color="#F3F3F3"/></Link>
          <Link className="link"to="/auth/signup"><Button boxShadow="0px 4px #d8af34ef"fontSize="20px"text="Get Started" width= "180px" color="#FFDC76"/></Link>
        </div>
      </div>
      <div className='hero-right'>
        <img className="hero-img" src="https://media.discordapp.net/attachments/407710496670875661/1095608007406141510/image_2.png"></img>
      </div>
    </div>
  )
}

export default Hero

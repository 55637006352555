import React from 'react'
import TTS from './TTS' 
import { textInputQuestions } from './TextInputQuestion'
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import './TTSButton.css'
import correctAnswer from '../src/audio/correctAnswer.mp3'
import wrongAnswer from '../src/audio/wrongAnswer.mp3'

function TTSBlock(props) {

  function handleSubmit(e) {
    e.preventDefault();
  }

  const index = props.index

  const enableTTS = () => {

    TTS(vocabWords[index].word); 

  }


  const checkVoiceInputAnswer = () => {
    var inputValue = document.getElementById("voiceInputAnswer"+index).value; 

    if(inputValue !== vocabWords[index].word){
      alert(vocabWords[index].tip)
      new Audio(wrongAnswer).play()
    } else if (inputValue === vocabWords[index].word){
      alert("好嘢! Well Done!")
      new Audio(correctAnswer).play()
    }
    document.forms['pronounciationInputForm'].reset();
  }

  return (
    <div className='TTSButton'>
      <center>
        <p className='question-count'>PRONUNCIATION</p>
        <div className='pronunciation'>
          <p className='vocabWord'>{vocabWords[index].word + vocabWords[index].jyutping}</p>
          <button onClick={() => enableTTS()}><VolumeUpRoundedIcon fontSize='large' /></button>
        </div>
        <br></br>
        <div className='pronounciationInput'>
        <p className='pronounciationInstructions'>Use voice input in order to practice pronouncing the word. The voice input requires you to have a precise pronunciation. If correct, you should be able to see the character(s) above and select it on your keyboard. Refer back to Unit 0 for more guidance.</p>
          <form className='pronounciationInputForm' id="pronounciationInputForm"onSubmit={handleSubmit}>
            <input id={"voiceInputAnswer"+index} className="voiceInputAnswer"placeholder='Input answer'></input>
            <button onClick={()=> checkVoiceInputAnswer()} className="voiceInputSubmit">Check Answer</button>
          </form>
        </div>
      </center>
    </div>
  )
}

export const vocabWords = [
  {
    word: "你", 
    jyutping: " (nei5)", 
    tip: "No pressure if you got the answer wrong! For a more proper pronunciation, remember that 'nei' is like the 'nei' in 'horse neighing.' This word is in the 5th tone, meaning that it is a low tone. This has the same tone as 我 (ngo5) so you can use that as a reference point to nail the right tone down!"
  }, 
  {
    word: "我", 
    jyutping: " (ngo5)", 
    tip: "No pressure if you got the answer wrong! For a more proper pronunciation, remember that this word sounds a bit nasally. This word is in the 5th tone, meaning that it is a low tone."
  }, 
  {
    word: "想", 
    jyutping: " (soeng2)", 
    tip: "No pressure if you got the answer wrong! For a more proper pronunciation, remember that this word is in the 2nd tone, meaning that it is a high rising tone."
  }, 
  {
    word: "食", 
    jyutping: " (sik6)", 
    tip: "No pressure if you got the answer wrong! Remember that this word is in the 6th tone, meaning that it is a low tone."
  }, 
  {
    word: "去", 
    jyutping: " (heoi3)", 
    tip: "No pressure if you got the answer wrong! We understand that the 'eoi' sound is really hard to nail down as a non Cantonese spaeker. For more personal help, you should join our Discord! Remember that this word is in the 3rd tone, meaning that it is a middle tone."
  }, 
  {
    word: "係", 
    jyutping: " (hai6)", 
    tip: "No pressure if you got the answer wrong! Remember that this word is in the 6th tone, meaning that it is a low tone. For more personal help, you should join our Discord!"
  }, 
  {
    word: "好", 
    jyutping: " (hou2)", 
    tip: "No pressure if you got the answer wrong! Remember that this word is in the 2nd tone, meaning that it is a mid rising tone. For more personal help, you should join our Discord!"
  }, 
  {
    word: "你好", 
    jyutping: " (nei5 hou2)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "一", 
    jyutping: " (jat1)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "二", 
    jyutping: " (ji6)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "三", 
    jyutping: " (saam1)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "四", 
    jyutping: " (sei3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "五", 
    jyutping: " (ng5)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "六", 
    jyutping: " (luk6)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "七", 
    jyutping: " (cat1)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "八", 
    jyutping: " (baat3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "九", 
    jyutping: " (gau2)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "十", 
    jyutping: " (sap6)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "個", 
    jyutping: " (go3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "六個", 
    jyutping: " (luk6 go3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "我想六個", 
    jyutping: " (ngo5 soeng2 luk6 go3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "两", 
    jyutping: " (loeng5)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "两個", 
    jyutping: " (loeng5 go3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "你好靚", 
    jyutping: " (nei5 hou2 leng3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "呢個", 
    jyutping: " (ni1 go3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "呢個好靚", 
    jyutping: " (ni1 go3 hou2 leng3)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
  {
    word: "呢個好甜", 
    jyutping: " (ni1 go3 hou2 tim4)", 
    tip: "No pressure if you got the answer wrong! If you got it wrong, it was most likely because of the tone. Listen closely and try to match it! For more personal help, you should join our Discord!"
  }, 
];



export default TTSBlock

import React from 'react'
import { Link } from 'react-router-dom'
import './Login.css'
import { auth } from './firebaseApp';
import { useState } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";


export default function Login() {


  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const signUp = () =>{

  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      console.log(user)
      alert("Successfully created new account!")
      
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
  }
  
  return (
    <div className='loginPage'>
      <div className='top-logo'>
      <Link to="/"><img className="logo" alt="logo" src="https://media.discordapp.net/attachments/407710496670875661/1095616063443578930/quackko-logo.png"></img></Link>
      </div>
      <p>Start learning Cantonese with Quackko today!</p>
      <div>
        <input className="inputField" type={"email"} placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)}/>
        <input className="inputField" type={"password"} placeholder='Enter Password' onChange={(e) => setPassword(e.target.value)}/>
        <div className='creatingAcc'>
          <button style={{boxShadow:"0px 4px #d8af34ef"}} className="signIn" onClick={signUp}>Create Account</button>
        </div>
      </div>
      <div className='noAcc'>
          <p>Already have an account? <span><Link to="/auth/login">Sign In!</Link></span></p>
        </div>
        <div className='loginPage1'>

      </div>
    </div>
  )
}


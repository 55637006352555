import React from 'react'
import QuestionBlock from './QuestionBlock'
import './U0L1.css'
import Progress from './Progress'
import Popup from './Popup'
import BottomBar from './BottomBar'

function U0L1() {
  return (
    <div className='U0L1'>
      <BottomBar />
      <section className="welcome">
        <center className='text'>Welcome to <span className="welcomeQuackko">Quackko!</span></center>
      </section>
      <section>
        <p className='text'>Throughout this course, we will teach you how to speak, read, and write Cantonese. In Cantonese, we use Chinese characters, which involves many strokes. It is important to practice writing as much as you can to make sure that you will not forget how to write it. Often, native speakers can read and speak, but forget how to write many characters if it is not practiced often. 
</p>
      </section>
      <section>
        <p className='text'>There are two different ways you may practice writing.  
</p>
      </section>
      <section>
        <p className='text'>1. Handwriting Keyboard 
</p>
      </section>
      <section>
        <p className='text'>The handwriting keyboard is labeled something like “Cantonese, Traditional - Handwriting” in your device's settings.
</p>
      </section>
      <section>
        <img className="writingIMG"src="https://media.discordapp.net/attachments/407710496670875661/1093352684775555172/IMG_2928.png?width=850&height=550"></img>
        <p className='text'>It gives you an area for you to “write” with your fingers. Whether or not that is beneficial is up to your goals as a learner. </p>
      </section>
      <section>
        <p className='text'>2. Stroke Keyboard</p>
      </section>
      <section>
        <p className='text'>The second keyboard option is typically labeled as “Cantonese, Traditional - Stroke” in your device’s settings.</p>
      </section>
      <section>
        <img className="keyboardIMG"src="https://media.discordapp.net/attachments/407710496670875661/1093354730798977114/IMG_2931.png?width=850&height=550"/>
        <p className='text'>This keyboard also utilizes your knowledge of strokes and writing in Chinese.</p>
      </section>
      <section>
        <p className='text'>Although this may seem confusing right now, it will be much more clear after you learn about the strokes and how to write the characters in the later lessons. </p>
      </section>
      <section>
        <p className='text'>If you are using a computer, the keyboard will not be displayed on the screen like it is on mobile. You will have to use a typical QWERTY keyboard in order to access the strokes.</p>
      </section>
      <section>
        <img className='macKeyboardIMG' src='https://media.discordapp.net/attachments/407710496670875661/1123153079135637614/image.png?width=680&height=506'></img>
        <p>Source: <a href="https://support.apple.com/guide/chinese-input-method/phonetic-cantonese-cimcba750589/mac">Apple Support (Click for more info)</a></p>
        <p className='text'>If you choose to use the stroke keyboard, this would be how it is used on Macs. Each key is a different stroke.</p>
      </section>
      <section>
        <p className='text'>Unfortunately, typing on Windows is not as straight forward and third party services need to be installed in order to do so. A popular choice is the CPIME Cantonese Jyutping Keyboard.</p>
      </section>
      <section>
        <img className="writingIMG" alt="Speech to Text Feature on Cantonese Keyboards" src='https://media.discordapp.net/attachments/407710496670875661/1137681187956080640/quackko_characters_16.png?width=468&height=468'></img>
        <p className='text'>In order to practice speaking, we would suggest using voice input. Get any Cantonese keyboard on your phone and you should be able to use speech-to-text function. Because of how many words there are in the language and how many tones there are, using this function will train you to speak with accurate tones and pronunciation. 
</p>
      </section>
      <section className="welcome">
        <Popup  className="popUp"/>
      </section>
    </div>
  )
}

export default U0L1
